import type { Category, ContentParsingStatus, WordBoundary } from './index';

// Modeled after ePub asset types. New types may be added in the future.
export enum DocumentChunkType {
  Unknown = 'UNKNOWN',
  Image = 'IMAGE',
  Style = 'STYLE',
  Script = 'SCRIPT',
  Navigation = 'NAVIGATION',
  Vector = 'VECTOR',
  Font = 'FONT',
  Video = 'VIDEO',
  Audio = 'AUDIO',
  Document = 'DOCUMENT',
  Cover = 'COVER',
  Smil = 'SMIL',
}

// Chunk stored in the chunkedDocumentStore, downloaded from server via document_content_v2 endpoint.
export type DocumentChunk = {
  // ID unique within the document
  internal_id: string;
  // base64 encoded, potentially encrypted
  data: string;
  // necessary to make internal links across segments work. these links combine filename and target anchor ID.
  filename: string;
  password: string | null;
  is_encrypted: boolean;
  html_child_node_count: number | null;
  word_count: number | null;
  type: DocumentChunkType;
};

export type DocumentChunkMap = { [internalId: string]: DocumentChunk; };

// Type of item stored in chunkedDocumentStore. Identical to `DocumentContent` except for `chunks` and `spine` fields.
export type ChunkedDocumentContent = {
  id: string; // Parsed document ID, NOT document ID
  // At some point we will switch over _all_ docs to the new content_v2 endpoint, in which case there will be some docs
  // with chunked content and some docs with concatenated content.
  // If we don't make every concatenated doc have only a single chunk, then this `html` property could still be required.
  html?: string;
  status: ContentParsingStatus;
  url: string;
  chunks?: DocumentChunkMap;
  // map from index (as string) to document chunk IDs, if present. modeled after ePub format.
  spine?: { [index: string]: string; };
  tts?: {
    [voice: string]: {
      word_boundaries_v3: WordBoundary[];
    };
  };
  tts_status: ContentParsingStatus;
};

// output of useDocumentChunkContentMap(), a function responsible for unpacking and decrypting chunk content from Store.
export type DocumentChunkContentMap = {
  [chunkId: string]: DocumentChunkTextContent | undefined;
};

// chunk content containing text data
export type DocumentChunkTextContent = {
  type:
    | DocumentChunkType.Document
    | DocumentChunkType.Style
    | DocumentChunkType.Smil
    | DocumentChunkType.Script
    | DocumentChunkType.Navigation;
  id: string;
  text: string;
  data: undefined;
};

// chunk content containing binary data
export type DocumentChunkDataContent = {
  type:
    | DocumentChunkType.Unknown
    | DocumentChunkType.Image
    | DocumentChunkType.Vector
    | DocumentChunkType.Font
    | DocumentChunkType.Video
    | DocumentChunkType.Audio
    | DocumentChunkType.Cover;
  id: string;
  text: undefined;
  data: ArrayBuffer;
};

export type DocumentChunkContent = DocumentChunkTextContent | DocumentChunkDataContent;

export type WebviewDocumentChunk = {
  // ID which is unique within the document
  id: string;
  // index in the document spine
  index: number;
  // original filename in the EPUB
  filename: string;
  // count of top-level nodes within the <body> tag.
  // NOTE: includes text nodes (i.e. not just elements).
  html_child_node_count: number;
  // HTML content of chunk, if loaded from content store.
  // includes images inlined as base64 data URLs.
  content: string | null;
};

export type WebviewStyleChunk = {
  id: string;
  // original filename in the EPUB
  filename: string;
  // the text content of the style tag
  content: string;
};

export type WebviewChunks = {
  document: WebviewDocumentChunk[];
  style: WebviewStyleChunk[];
};

export type ChunkSanitizationOptions = {
  isOriginalEmailView: boolean;
  showEnhancedYouTubeTranscript: boolean | undefined;
  category: Category;
};
