import './css/main.css';
import 'shared/types/window';

import * as Sentry from '@sentry/react';
import React, { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, DragStart, DropResult } from 'react-beautiful-dnd';
import {
  BrowserRouter as Router,
  matchPath,
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom';
import {
  openBulkActionsSubMenu,
  openFeedsSubMenu,
  openFiltersSubMenu,
  openSaveDocSubMenu,
  openShortcutsSubmenu,
  openViewFiltersSubMenu,
} from 'shared/foreground/cmdPalette';
import eventEmitter from 'shared/foreground/eventEmitter';
import { ForegroundEventName, globalState, useIsStaffProfile } from 'shared/foreground/models';
import { getDocument } from 'shared/foreground/stateGetters';
import { sortViews, usePartialDocument } from 'shared/foreground/stateHooks';
import { toggleDarkModeTheme } from 'shared/foreground/stateUpdaters/clientStateUpdaters/other';
import { markDocumentAsOpened } from 'shared/foreground/stateUpdaters/persistentStateUpdaters/documents/anyDocument';
import { setCurrentlyReading } from 'shared/foreground/stateUpdaters/persistentStateUpdaters/other';
import {
  addRouteToNavigationStack,
  setOpenDocumentId,
  setOpenNotebookId,
  setPathNameToRedirectAfterOnboarding,
  setWebEffectiveTheme,
} from 'shared/foreground/stateUpdaters/transientStateUpdaters/other';
import {
  redoLastDocumentAction,
  undoLastDocumentAction,
} from 'shared/foreground/stateUpdaters/transientStateUpdaters/undo';
import { onPageChange } from 'shared/foreground/userEvents';
import combineClasses from 'shared/foreground/utils/combineClasses';
import getUIFriendlyNameForDocumentLocation from 'shared/foreground/utils/getUIFriendlyNameForDocumentLocation';
import useDocumentLocations from 'shared/foreground/utils/useDocumentLocations';
import useGlobalStateWithFallback from 'shared/foreground/utils/useGlobalStateWithFallback';
import useScreenWidthMonitor from 'shared/foreground/utils/useScreenWidthMonitor';
import {
  DefaultPage,
  DisplayTheme,
  DocumentLocation,
  FeedDocumentLocation,
  OnboardingStep,
  PersistentStateLoadingState,
  ReadingStatus,
  SubscriptionProduct,
} from 'shared/types';
import { ShortcutId } from 'shared/types/keyboardShortcuts';
import { isFirstClassDocument } from 'shared/typeValidators';
import { BrowserType, getCurrentBrowser, isDesktopApp, isDevOrTest } from 'shared/utils/environment';
import { allDefaultCategoriesQueries } from 'shared/utils/filteredViews';
import { safeDecodeURIComponent } from 'shared/utils/safeDecodeURIComponent';
import urlJoin from 'shared/utils/urlJoin';
import useDebounce from 'shared/utils/useDebounce';

import styles from './App.module.css';
import DatabaseQueryOptimizer from './components/DatabaseQueryOptimizer';
import ErrorFallback from './components/ErrorFallback';
import FAQRedirect from './components/FAQRedirect';
import FileDropzone from './components/FileDropzone';
import Ghost from './components/Ghost';
import { InboxPage } from './components/InboxPage';
import { NOTEBOOK_PATH_PATTERN } from './components/NotebookView/constants';
import Spinner from './components/Spinner';
import { TrashPage } from './components/TrashPage';
import TtsPlayer from './components/TtsPlayer';
import { useIsRightSidebarHidden, useScrollToTop } from './hooks/hooks';
import { useKeyboardShortcut, useKeyboardShortcutPreventDefault } from './hooks/useKeyboardShortcut';
import { useShouldForceMobileApp } from './hooks/useShouldForceMobileApp';
import {
  hideReaderViewSidebars,
  setShouldRunSidebarItemCounts,
  toggleDesktopNavigationSidebar,
  toggleHideLeftSidebar,
  toggleHideRightSidebar,
  toggleHideSidebars,
} from './stateUpdaters/sidebars';
import { getCookie, setCookie } from './utils/cookies';
import { reactLazy, reactLazyPickExport } from './utils/dynamicImport';
import { getFilteredViewPath } from './utils/getFilteredViewPath';
import { isNotebookViewPath, readerViewUrl, shouldShowBulkActionsMenu } from './utils/pathnameHelpers';
import { useShortcutsMap } from './utils/shortcuts';
import updateDocumentTitle from './utils/updateDocumentTitle';
import { checkForDesktopUpdates } from './utils/updates.desktop';
import useLocation from './utils/useLocation';

const DatabaseExplorer = reactLazy(() => import('./components/DatabaseExplorer'));
const NotebookPage = reactLazyPickExport(
  () => import('./components/NotebookView/NotebookPage'),
  'NotebookPage',
);
const QuoteshotModal = reactLazy(() => import('./components/QuoteshotModal'));
const CommandPalette = reactLazy(() => import('./components/CommandPalette/CommandPalette'));
const RightSidebar = reactLazyPickExport(
  () => import('./components/RightSidebar/RightSidebar'),
  'RightSidebar',
);
const FeedsSourceList = reactLazyPickExport(
  () => import('./components/FeedsSourceList'),
  'FeedsSourceList',
);
const FeedsSuggestionList = reactLazyPickExport(
  () => import('./components/FeedsSuggestionList'),
  'FeedsSuggestionList',
);
const FilterPage = reactLazyPickExport(() => import('./components/FilterPage'), 'FilterPage');
const HomePage = reactLazy(() => import('./components/HomePage'));
const ProfilePage = reactLazyPickExport(
  () => import('./components/AccountSettings/ProfilePage'),
  'ProfilePage',
);
const PreferencesPage = reactLazyPickExport(
  () => import('./components/AccountSettings/PreferencesPage'),
  'PreferencesPage',
);
const EmailPreferencesPage = reactLazyPickExport(
  () => import('./components/AccountSettings/EmailPreferencesPage'),
  'EmailPreferencesPage',
);
const AddToLibraryPage = reactLazyPickExport(
  () => import('./components/AccountSettings/AddToLibraryPage'),
  'AddToLibraryPage',
);
const AddToFeedPage = reactLazyPickExport(
  () => import('./components/AccountSettings/AddToFeedPage'),
  'AddToFeedPage',
);
const ResourcesPage = reactLazyPickExport(
  () => import('./components/AccountSettings/ResourcesPage'),
  'ResourcesPage',
);
const ShortcutsPage = reactLazyPickExport(
  () => import('./components/AccountSettings/ShortcutsPage'),
  'ShortcutsPage',
);
const GhostreaderPromptsPage = reactLazyPickExport(
  () => import('./components/AccountSettings/GhostreaderPromptsPage'),
  'GhostreaderPromptsPage',
);
const IntegrationsPage = reactLazyPickExport(
  () => import('./components/AccountSettings/IntegrationsPage'),
  'IntegrationsPage',
);
const WisereadsDiscovery = reactLazyPickExport(
  () => import('./components/WisereadsDiscovery'),
  'WisereadsDiscovery',
);
const ModalsContainer = reactLazy(() => import('./components/Modals/ModalsContainer'));
const OnboardingPage = reactLazy(() => import('./components/OnboardingPage'));
const SearchPage = reactLazyPickExport(() => import('./components/SearchPage/SearchPage'), 'SearchPage');
const TagsList = reactLazyPickExport(() => import('./components/TagsList'), 'TagsList');
const ToastContainer = reactLazy(() => import('./components/ToastContainer'));
const InboxSidebar = reactLazy(() => import('./components/InboxSidebar'));
const ViewsList = reactLazyPickExport(() => import('./components/ViewsList'), 'ViewsList');
const RssFolderPage = reactLazyPickExport(() => import('./components/RssFolderPage'), 'RssFolderPage');

const TrackActiveItemPage = ({ name }: { name: string; }) => {
  const {
    uncategorizedDocumentIdToOpen,
    openDocumentId,
    documentLocation,
    notebookId: openNotebookId,
  } = useParams<{
    openDocumentId?: string;
    notebookId?: string;
    documentLocation?: DocumentLocation;
    uncategorizedDocumentIdToOpen?: string;
  }>();

  const docId = useMemo(
    () => uncategorizedDocumentIdToOpen ?? openDocumentId,
    [openDocumentId, uncategorizedDocumentIdToOpen],
  );
  useEffect(() => {
    setOpenDocumentId(docId ?? null);

    return () => {
      setOpenDocumentId(null);
    };
  }, [docId]);

  useEffect(() => {
    setOpenNotebookId(openNotebookId ?? null);

    return () => {
      setOpenNotebookId(null);
    };
  }, [openNotebookId]);

  const [existingDoc] = usePartialDocument(docId, ['id', 'category', 'reading_status']);

  useEffect(() => {
    const nameToUse = docId ? 'document' : name;

    updateDocumentTitle(
      nameToUse.toLowerCase() === 'inbox' && documentLocation
        ? getUIFriendlyNameForDocumentLocation(documentLocation)
        : nameToUse,
    );
    onPageChange({
      name: nameToUse,
      itemId: docId ?? null,
    });

    if (docId) {
      markDocumentAsOpened(docId);
      if (
        existingDoc &&
        isFirstClassDocument(existingDoc) &&
        existingDoc.reading_status !== ReadingStatus.Archived
      ) {
        setCurrentlyReading(existingDoc.id, { userInteraction: 'unknown' });
      }
    }
  }, [name, documentLocation, existingDoc, docId, openDocumentId]);
  return null;
};

function DevOnly({ children }: { children: React.ReactElement | React.ReactElement[]; }) {
  if (!isDevOrTest) {
    return <Redirect to="/" />;
  }
  return <>{children}</>;
}

const Main = () => {
  const documentLocations = useDocumentLocations();
  const { pathname, search } = useLocation();
  const history = useHistory();
  const isReaderViewUrl = readerViewUrl.test(pathname);
  const isNotebookViewUrl = isNotebookViewPath(pathname);
  const isFeedsSources = pathname.startsWith('/feed/sources');
  const isTagsList = pathname.startsWith('/tags');
  const isViewsList = pathname.startsWith('/views');
  const isPreferencesPage = pathname.startsWith('/preferences');
  const isEmailPreferencesPage = pathname.startsWith('/product-emails');
  const isProfilePage = pathname.startsWith('/profile');
  const isIntegrationsPage = pathname.startsWith('/integrations');
  const isAddToLibraryPage = pathname.startsWith('/add-to-library');
  const isAddToFeedPage = pathname.startsWith('/add-to-feed');
  const isResourcesPage = pathname.startsWith('/resources');
  const persistentStateLoaded = globalState(useCallback((state) => state.persistentStateLoaded, []));
  const theme = globalState(useCallback((state) => state.client.theme, []));
  const clientStateLoaded = globalState(useCallback((state) => state.clientStateLoaded, []));
  const hasOnboarded = globalState(
    useCallback((state) => state.persistent && state.persistent.onboardedAt !== null, []),
  );
  const themeMediaQueryRef = useRef<MediaQueryList>();
  const onboardingPaths = useMemo(
    () => ['/welcome', '/welcome/extension', '/welcome/mobile', '/welcome/app', '/welcome/ready'],
    [],
  );
  const isOnboarding = onboardingPaths.some((path) => pathname.startsWith(path));
  const shouldShowInboxSidebar = !isReaderViewUrl && !isNotebookViewUrl && !isOnboarding;

  useEffect(() => {
    const newPath = `${pathname}${search || ''}`;
    addRouteToNavigationStack(safeDecodeURIComponent(newPath));
  }, [pathname, search]);

  useEffect(() => {
    if (!isReaderViewUrl) {
      hideReaderViewSidebars(false, { userInteraction: 'unknown' });
    }
  }, [isReaderViewUrl]);

  useScreenWidthMonitor();

  const applyTheme = useCallback((newTheme: DisplayTheme) => {
    const page = document.querySelector('html') as HTMLHtmlElement;
    const currentThemes = Array.from(page.classList).filter((c) => c.startsWith('theme--'));
    currentThemes.forEach((themeClass) => {
      page.classList.remove(themeClass);
    });
    page.classList.add(`theme--${newTheme}`);
    setWebEffectiveTheme(newTheme);
    setCookie('theme', newTheme);
  }, []);

  useEffect(() => {
    if (!clientStateLoaded) {
      const theme = getCookie('theme') as DisplayTheme;
      if (theme) {
        applyTheme(theme);
      }
      return;
    }

    let newTheme;

    if (window.matchMedia) {
      if (!themeMediaQueryRef.current) {
        themeMediaQueryRef.current = window.matchMedia('(prefers-color-scheme: dark)');
      }

      if (theme === DisplayTheme.System) {
        const isSystemDarkTheme = themeMediaQueryRef.current.matches;
        newTheme = isSystemDarkTheme ? DisplayTheme.Dark : DisplayTheme.Light;
        themeMediaQueryRef.current.onchange = (e) => {
          applyTheme(e.matches ? DisplayTheme.Dark : DisplayTheme.Light);
        };
      } else {
        newTheme = theme === DisplayTheme.Dark ? DisplayTheme.Dark : DisplayTheme.Light;
        themeMediaQueryRef.current.onchange = null;
      }
    } else {
      newTheme = theme === DisplayTheme.Dark ? DisplayTheme.Dark : DisplayTheme.Light;
    }

    applyTheme(newTheme);
  }, [theme, applyTheme, clientStateLoaded]);

  const appRef = useRef(null);

  const shortcutsMap = useShortcutsMap();

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.Search],
    useCallback(() => {
      const isSearchView = history.location.pathname === '/search';

      if (isSearchView) {
        return;
      }

      history.push('/search');
    }, [history]),
    { description: 'Search' },
  );

  const onUndoPressed = () => undoLastDocumentAction('keypress');
  useKeyboardShortcut(shortcutsMap[ShortcutId.Undo], onUndoPressed, { description: 'Undo' });
  useKeyboardShortcut(
    shortcutsMap[ShortcutId.Redo],
    useCallback(async () => redoLastDocumentAction('keypress'), []),
    { description: 'Redo' },
  );
  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.OpenShortcutsMenu],
    useCallback(async () => openShortcutsSubmenu(), []),
  );

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.GoToHome],
    useCallback(() => {
      history.push('/home');
    }, [history]),
    {
      shouldShowInHelp: false,
    },
  );

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.GoToLibrary],
    useCallback(() => {
      history.push('/library');
    }, [history]),
    {
      shouldShowInHelp: false,
    },
  );

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.Feed],
    useCallback(() => {
      history.push('/feed');
    }, [history]),
    {
      shouldShowInHelp: false,
    },
  );

  const goToPinnedViewIndex = useCallback(
    (index: number) => {
      const stateViews = globalState.getState().persistent.filteredViews || {};
      const views = Object.keys(stateViews ?? {}).map((id) => stateViews[id]);
      const pinnedViews = views
        .filter((view) => !allDefaultCategoriesQueries.includes(view.query) && view.isUnpinned !== true)
        .sort(sortViews);
      const view = pinnedViews[index];

      if (view) {
        const url = getFilteredViewPath(view, documentLocations);
        history.push(url);
      }
    },
    [documentLocations, history],
  );

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.GoToPinnedView1],
    useCallback(() => {
      goToPinnedViewIndex(0);
    }, [goToPinnedViewIndex]),
    {
      shouldShowInHelp: false,
    },
  );

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.GoToPinnedView2],
    useCallback(() => {
      goToPinnedViewIndex(1);
    }, [goToPinnedViewIndex]),
    {
      shouldShowInHelp: false,
    },
  );

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.GoToPinnedView3],
    useCallback(() => {
      goToPinnedViewIndex(2);
    }, [goToPinnedViewIndex]),
    {
      shouldShowInHelp: false,
    },
  );

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.GoToPinnedView4],
    useCallback(() => {
      goToPinnedViewIndex(3);
    }, [goToPinnedViewIndex]),
    {
      shouldShowInHelp: false,
    },
  );

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.GoToPinnedView5],
    useCallback(() => {
      goToPinnedViewIndex(4);
    }, [goToPinnedViewIndex]),
    {
      shouldShowInHelp: false,
    },
  );

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.GoToPinnedView6],
    useCallback(() => {
      goToPinnedViewIndex(5);
    }, [goToPinnedViewIndex]),
    {
      shouldShowInHelp: false,
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.HidePanels],
    useCallback(async () => {
      await toggleHideSidebars({ userInteraction: 'keyup' });
    }, []),
    { description: 'Toggle panel hidden' },
  );
  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.HideLeftPanel],
    useCallback(async () => {
      if (isReaderViewUrl || isNotebookViewUrl) {
        await toggleHideLeftSidebar({ userInteraction: 'keyup' });
      } else {
        await toggleDesktopNavigationSidebar({ userInteraction: 'keyup' });
      }
    }, [isReaderViewUrl, isNotebookViewUrl]),
    { description: 'Toggle left panel hidden' },
  );
  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.HideRightPanel],
    useCallback(async () => {
      await toggleHideRightSidebar({ userInteraction: 'keyup' });
    }, []),
    { description: 'Toggle right panel hidden' },
  );
  useKeyboardShortcut(
    shortcutsMap[ShortcutId.ToggleDarkMode],
    useCallback(async () => {
      await toggleDarkModeTheme();
    }, []),
    { description: 'Toggle dark mode' },
  );
  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.ManageFeedSubscriptions],
    useCallback(async () => {
      await openFeedsSubMenu();
    }, []),
    { description: 'Add/remove RSS subscriptions' },
  );
  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.OpenFiltersCmdPanel],
    useCallback(async () => {
      await openFiltersSubMenu();
    }, []),
    { description: 'Filter all documents' },
  );
  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.OpenFilteredViews],
    useCallback(async () => {
      await openViewFiltersSubMenu();
    }, []),
    { description: 'Open quick view switcher' },
  );
  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.OpenBulkActionsSubMenu],
    useCallback(async () => {
      if (shouldShowBulkActionsMenu(pathname)) {
        await openBulkActionsSubMenu();
      }
    }, [pathname]),
    { description: 'Apply bulk actions' },
  );
  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.OpenSaveDocFromUrlPalette],
    useCallback(async () => {
      await openSaveDocSubMenu();
    }, []),
    { description: 'Save doc from URL' },
  );

  useScrollToTop(appRef.current);

  const documentLocationUrlPlaceholder = `:documentLocation(${Object.values(DocumentLocation)
    .filter((documentLocation) => documentLocation !== DocumentLocation.Deleted)
    .join('|')})`;
  const feedDocumentLocationUrlPlaceholder = `/:documentLocation(feed)/:feedDocumentLocation(${Object.values(
    FeedDocumentLocation,
  ).join('|')})`;
  const rightSidebarHidden = useIsRightSidebarHidden();

  const hasRightSidebar =
    persistentStateLoaded &&
    ![
      '/home',
      '/feed/suggestions',
      '/welcome',
      '/welcome/extension',
      '/welcome/mobile',
      '/welcome/app',
      '/welcome/ready',
      '/preferences/shortcuts',
      '/trash',
    ].some((urlPath) =>
      matchPath(window.location.pathname, {
        path: urlPath,
        exact: true,
      }));

  // We don't want to animate the sidebars if we are coming from the list with
  // sidebars already hidden.
  const possibleTransitionClassName = isReaderViewUrl ? styles.withTransition : '';
  const debouncedTransitionClassName = useDebounce(possibleTransitionClassName, 500);
  const transitionClassName =
    isReaderViewUrl ||
    isFeedsSources ||
    isTagsList ||
    isViewsList ||
    isProfilePage ||
    isPreferencesPage ||
    isEmailPreferencesPage ||
    isAddToLibraryPage ||
    isAddToFeedPage ||
    isResourcesPage ||
    isIntegrationsPage
      ? debouncedTransitionClassName
      : styles.withTransition;

  const appContentClasses = ['appContent', styles.appContent, transitionClassName];
  if (rightSidebarHidden) {
    appContentClasses.push(styles.rightSidebarHidden);
  }
  if (isDevOrTest) {
    appContentClasses.push('isDev');
  }

  const onboardingStep = useGlobalStateWithFallback(
    OnboardingStep.Welcome,
    useCallback((state) => state.persistent.onboardingStep, []),
  );

  const subscription = globalState(useCallback((state) => state.client?.profile?.subscription, []));
  const shouldForceMobileApp = useShouldForceMobileApp();

  const isFirstLoad = useRef(true);
  const [hasFirstLoadedHappened, setHasFirstLoadHappened] = useState(false);
  const [safariLongLoadWarning, setSafariLongLoadWarning] = useState(false);

  useEffect(() => {
    if (!persistentStateLoaded || !hasFirstLoadedHappened) {
      return;
    }

    if (hasOnboarded && !shouldForceMobileApp) {
      return;
    }

    setPathNameToRedirectAfterOnboarding(pathname);
    history.push(onboardingStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistentStateLoaded, hasOnboarded, shouldForceMobileApp, hasFirstLoadedHappened]);

  useEffect(() => {
    if (!persistentStateLoaded) {
      return;
    }

    setTimeout(() => {
      isFirstLoad.current = false;
      setHasFirstLoadHappened(true);
    }, 0);
  }, [persistentStateLoaded]);

  useEffect(() => {
    if (persistentStateLoaded) {
      setTimeout(() => {
        setShouldRunSidebarItemCounts(true);
      }, 10 * 1000);
    }
  }, [persistentStateLoaded]);

  useEffect(() => {
    if (persistentStateLoaded) {
      return;
    }
    setTimeout(() => {
      if (!persistentStateLoaded && getCurrentBrowser() === BrowserType.Safari) {
        setSafariLongLoadWarning(true);
      }
    }, 6 * 1000);
  }, [persistentStateLoaded]);

  const isStaffProfile = useIsStaffProfile();

  const defaultPage = globalState((state) => {
    const defaultPageVal = state.persistent.settings.defaultPage ?? DefaultPage.Library;
    const currentlyReadingId = state.persistent.currentlyReadingId;
    if (defaultPageVal === DefaultPage.CurrentlyReading) {
      if (currentlyReadingId && isFirstLoad.current) {
        // Note that in the useEffect below, we ensure that the document we are redirecting to exists.
        // return below should match defaultPage === [return value] in useEffect below,
        // so if you change this return, change it also in useEffect below
        return urlJoin(['read', currentlyReadingId]);
      }

      return DefaultPage.Library;
    }
    return defaultPageVal;
  });

  useEffect(() => {
    const state = globalState.getState();
    const currentlyReadingId = state.persistent.currentlyReadingId;
    // Make sure currentlyReadingDocument exists
    if (currentlyReadingId && defaultPage === urlJoin(['read', currentlyReadingId])) {
      getDocument(currentlyReadingId)
        .then((currentlyReadingDocumentExists) => {
          if (currentlyReadingDocumentExists) {
            return;
          }
          setCurrentlyReading(null, { userInteraction: null });
          history.push('/library');
        })
        .catch(() => {
          // nothing
        });
    }
  }, [defaultPage, history]);

  /*
    We wait for state to know which default page to open.
    NOTE: if that's ever removed, we need to show this anyway if it's the first time a user has opened reader
  */

  const persistentStateLoadingState = globalState((state) => state.persistentStateLoadingState);
  const totalDocumentCount = globalState((state) => state.persistentStateTotalDocumentsToAddCount);
  const addedDocumentsCount = globalState((state) => state.persistentStateNumberOfDocumentsAdded);

  const currentLoadingMessage = useMemo(() => {
    if (persistentStateLoadingState === PersistentStateLoadingState.DownloadingDocuments) {
      return 'Fetching all of your documents';
    }
    if (persistentStateLoadingState === PersistentStateLoadingState.AddingDocumentsToDatabase) {
      return 'Adding documents, please wait...';
    }
    if (safariLongLoadWarning) {
      return (
        <p style={{ textAlign: 'center' }}>
          Unfortunately, Safari is not optimized for local-first apps and can sometimes get stuck loading
          data.
          <br />
          If you&apos;re seeing this message, restart Safari or you may want to{' '}
          <a
            href="https://readwise.io/read/download"
            style={{ color: '#2d75e5' }}
            target="_blank"
            rel="noreferrer"
          >
            try our desktop app
          </a>
          <span>.</span>
        </p>
      );
    }
    return '';
  }, [persistentStateLoadingState, safariLongLoadWarning]);

  const currentLoadingSubMessage = useMemo(() => {
    if (persistentStateLoadingState === PersistentStateLoadingState.DownloadingDocuments) {
      return 'This initial load may take a moment...';
    }
    if (persistentStateLoadingState === PersistentStateLoadingState.AddingDocumentsToDatabase) {
      return `Added ${addedDocumentsCount}/${totalDocumentCount} documents`;
    }
    return '';
  }, [addedDocumentsCount, persistentStateLoadingState, totalDocumentCount]);

  const onDragStart = useCallback((result: DragStart) => {
    window.isReactDndDragging = true;
    eventEmitter.emit(ForegroundEventName.OnDragStart, result);
  }, []);

  const onDragEnd = useCallback((result: DropResult) => {
    window.isReactDndDragging = false;
    eventEmitter.emit(ForegroundEventName.OnDragEnd, result);
  }, []);

  if (!persistentStateLoaded) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner />
        <p>{currentLoadingMessage}</p>
        <p>{currentLoadingSubMessage}</p>
      </div>
    );
  }

  const appClasses = ['app', styles.app];
  if (isStaffProfile) {
    appClasses.push('is-staff');
  } else {
    appClasses.push('is-not-staff');
  }

  return (
    <>
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
          <FileDropzone className={combineClasses(appClasses)}>
            <div className={styles.appMainAndTtsPlayer}>
              <main className={styles.appMain}>
                <div className={combineClasses(appContentClasses)} ref={appRef}>
                  <Suspense fallback={null}>{shouldShowInboxSidebar && <InboxSidebar />}</Suspense>
                  <Suspense fallback={<div className="hideAccessibly">Loading...</div>}>
                    <Switch>
                      <Route
                        exact
                        path="/"
                        render={() => <Redirect to={urlJoin(['/', defaultPage])} />}
                      />
                      <Route
                        exact
                        path="/library"
                        render={() =>
                          <Redirect to={urlJoin(['/', documentLocations[0] || DocumentLocation.New])} />
                        }
                      />
                      <Route
                        exact
                        path="/inbox"
                        render={() =>
                          <Redirect exact from="/inbox" to={urlJoin(['/', DocumentLocation.New])} />
                        }
                      />
                      {/* Redirect to main app login for end-to-end tests */}
                      <Route
                        component={() => {
                          window.location.href = `https://${window.location.hostname}:8000/accounts/login`;
                          return null;
                        }}
                        path="/accounts/login"
                      />
                      <Route
                        component={() => {
                          window.location.href = `https://${window.location.hostname}:8000/empty`;
                          return null;
                        }}
                        path="/empty"
                      />

                      {/* remove trailing slashes */}
                      <Route path="/:url*(/+)" render={() => <Redirect to={pathname.slice(0, -1)} />} />
                      <Route
                        exact
                        path={`/${DocumentLocation.Feed}`}
                        render={() =>
                          <Redirect
                            to={urlJoin(['/', DocumentLocation.Feed, FeedDocumentLocation.New])}
                          />
                        }
                      />

                      <Route exact path="/profile">
                        <TrackActiveItemPage name="profile" />
                        <ProfilePage />
                      </Route>
                      {/* lock out users with expired plans */}
                      {subscription?.product === SubscriptionProduct.Expired
                        ? <Route path="*" render={() => <Redirect to="/profile" />} />
                       : null}

                      <Route exact path="/feed/sources">
                        <TrackActiveItemPage name="feed sources" />
                        <FeedsSourceList />
                      </Route>

                      <Route exact path="/feed/suggestions">
                        <TrackActiveItemPage name="feed suggestions" />
                        <FeedsSuggestionList />
                      </Route>

                      <Route exact path="/home">
                        <TrackActiveItemPage name="home" />
                        <HomePage />
                      </Route>

                      <Route exact path="/tags">
                        <TrackActiveItemPage name="tags" />
                        <TagsList />
                      </Route>

                      <Route exact path="/views">
                        <TrackActiveItemPage name="views" />
                        <ViewsList />
                      </Route>

                      <Route exact path={onboardingPaths}>
                        <TrackActiveItemPage name="onboarding" />
                        <OnboardingPage />
                      </Route>

                      <Route
                        exact
                        path={[
                          `/${documentLocationUrlPlaceholder}`,
                          `/${documentLocationUrlPlaceholder}/read/:openDocumentId`,
                          `/read/:uncategorizedDocumentIdToOpen`,
                          feedDocumentLocationUrlPlaceholder,
                          `${feedDocumentLocationUrlPlaceholder}/read/:openDocumentId`,
                        ]}
                      >
                        <TrackActiveItemPage name="inbox" />
                        <InboxPage />
                      </Route>
                      <Route exact path={NOTEBOOK_PATH_PATTERN}>
                        <TrackActiveItemPage name="notebook" />
                        <NotebookPage />
                      </Route>
                      <Route exact path="/preferences">
                        <TrackActiveItemPage name="preferences" />
                        <PreferencesPage />
                      </Route>
                      <Route exact path="/product-emails">
                        <TrackActiveItemPage name="product emails" />
                        <EmailPreferencesPage />
                      </Route>
                      <Route exact path="/add-to-library">
                        <TrackActiveItemPage name="Add to Library" />
                        <AddToLibraryPage />
                      </Route>
                      <Route exact path="/add-to-feed">
                        <TrackActiveItemPage name="Add to Feed" />
                        <AddToFeedPage />
                      </Route>
                      <Route exact path="/resources">
                        <TrackActiveItemPage name="Resources" />
                        <ResourcesPage />
                      </Route>
                      <Route exact path="/preferences/shortcuts">
                        <TrackActiveItemPage name="shortcuts" />
                        <ShortcutsPage />
                      </Route>
                      <Route exact path="/preferences/ghostreader">
                        <TrackActiveItemPage name="ghostreader" />
                        <GhostreaderPromptsPage />
                      </Route>
                      <Route exact path="/integrations">
                        <TrackActiveItemPage name="integrations" />
                        <IntegrationsPage />
                      </Route>
                      <Route exact path={['/search', '/search/read/:openDocumentId']}>
                        <TrackActiveItemPage name="search" />
                        <SearchPage />
                      </Route>
                      <Route
                        exact
                        path={[
                          '/filter/:filterQuery',
                          '/filter/:filterQuery/read/:openDocumentId',
                          '/filter/:filterQuery/split/:splitBy',
                          '/filter/:filterQuery/split/:splitBy/:splitValue',
                          '/filter/:filterQuery/split/:splitBy/:splitValue/read/:openDocumentId',
                        ]}
                      >
                        <TrackActiveItemPage name="filter" />
                        <FilterPage />
                      </Route>
                      <Route exact path="/rss-folder/:rssFolderFilteredViewId">
                        <TrackActiveItemPage name="rss-folder" />
                        <RssFolderPage />
                      </Route>
                      <Route exact path="/faq">
                        <FAQRedirect />
                      </Route>

                      <Route
                        exact
                        path="/database-explorer"
                        render={() =>
                          <DevOnly>
                            <DatabaseExplorer />
                          </DevOnly>
                        }
                      />
                      <Route
                        path="/database-query-optimizer"
                        render={() =>
                          <DevOnly>
                            <DatabaseQueryOptimizer />
                          </DevOnly>
                        }
                      />
                      <Route exact path="/trash">
                        <TrackActiveItemPage name="trash" />
                        <TrashPage />
                      </Route>
                      <Route exact path="/discover">
                        <TrackActiveItemPage name="discover" />
                        <WisereadsDiscovery />
                      </Route>
                    </Switch>
                  </Suspense>
                </div>
                <Suspense fallback={null}>{hasRightSidebar && <RightSidebar />}</Suspense>
              </main>
              <TtsPlayer />
            </div>
            <Suspense fallback={null}>
              <ModalsContainer />
              <CommandPalette />
              <QuoteshotModal />
            </Suspense>
          </FileDropzone>
        </Sentry.ErrorBoundary>
      </DragDropContext>

      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <Suspense fallback={null}>
          <ToastContainer />
        </Suspense>
        <Ghost />
      </Sentry.ErrorBoundary>
    </>
  );
};

// Wrap in Router for urls to work nicely:
const App = (): JSX.Element => {
  useEffect(() => {
    document.body.classList.add('react-has-mounted');
  }, []);

  useEffect(() => {
    if (isDesktopApp && !isDevOrTest) {
      checkForDesktopUpdates();
    }
  }, []);

  return (
    <Router>
      <Main />
    </Router>
  );
};

export default App;

// For debugging why components rendered:
// CommandPalette.whyDidYouRender = {
//   logOnDifferentValues: true,
// };
