import { useCallback, useEffect, useMemo, useState } from 'react';

import { type FirstClassDocument, type PartialDocument, Category } from '../../types';
import {
  type DocumentChunk,
  type DocumentChunkContentMap,
  type DocumentChunkTextContent,
  type WebviewDocumentChunk,
  DocumentChunkType,
  WebviewChunks,
} from '../../types/chunkedDocuments';
import { isArticle, isFirstClassDocument, notEmpty } from '../../typeValidators';
import exceptionHandler from '../../utils/exceptionHandler.platform';
import getUrlDomain from '../../utils/getUrlDomain';
import makeLogger from '../../utils/makeLogger';
import { fetchRelatedRSS } from '../methods';
import { fetchDocumentContent } from '../stateUpdaters/transientStateUpdaters/documentContent';
import { inlineImages } from '../utils/inlineImages';
import { extractTopLevelNodeIndex } from '../utils/locationSerialization/utils';
import { unpackChunk } from '../utils/unpackChunk';
import useStatePlusLiveValueRef from '../utils/useStatePlusLiveValueRef';
import { usePartialDocument } from './index';

const logger = makeLogger(__filename, { shouldLog: false });

/**
 * Loads set of chunks from disk and decrypts them if necessary, returning a map from chunk ID to chunk content.
 */
function useDocumentChunkContentMap(
  doc: PartialDocument<FirstClassDocument, 'id' | 'transientData'> | null,
  chunkIds: string[],
): DocumentChunkContentMap {
  const [chunkContentMap, setChunkContentMap, chunkContentMapRef] =
    useStatePlusLiveValueRef<DocumentChunkContentMap>({});
  const chunkMap = doc?.transientData.chunks;

  const chunksWhichShouldBeLoaded = useMemo(() => {
    if (!chunkMap) {
      return [];
    }
    return chunkIds
      .map((chunkId) => {
        const chunk = chunkMap[chunkId];
        if (!chunk) {
          logger.warn('Could not find chunk with ID', { chunkId });
          return undefined;
        }
        return chunk;
      })
      .filter(notEmpty);
  }, [chunkIds, chunkMap]);

  const inlineFileChunks = useCallback(
    async (html: string) => {
      if (!chunkMap) {
        return html;
      }

      const htmlWithInlinedImages = await inlineImages(html, chunkMap);

      return htmlWithInlinedImages;
    },
    [chunkMap],
  );

  // NOTE: this useEffect can be expensive because it might decrypt many chunks, on the main thread.
  //   therefore, ensure it is not run too often lest we degrade app performance.
  useEffect(() => {
    (async () => {
      const chunksToUnpack = chunksWhichShouldBeLoaded.filter(
        (chunk) => !(chunk.internal_id in chunkContentMapRef.current),
      );
      const unpackedChunks = await Promise.all(
        chunksToUnpack.map(async (chunk) => {
          const chunkContent = await unpackChunk(chunk);
          if (!chunkContent) {
            return undefined;
          }
          if (chunkContent.type === DocumentChunkType.Document) {
            chunkContent.text = await inlineFileChunks(chunkContent.text);
          }
          logger.debug('unpacked chunk content', {
            id: chunk.internal_id,
            size: chunkContent.text?.length,
          });
          return chunkContent as DocumentChunkTextContent;
        }),
      );
      setChunkContentMap((existingEntries) => {
        const chunkIdsWhichShouldBeLoaded = new Set(chunksWhichShouldBeLoaded.map((chunk) => chunk.internal_id));
        const filteredExistingEntries = Object
          .entries(existingEntries)
          .filter(([chunkId, _]) => chunkIdsWhichShouldBeLoaded.has(chunkId));
        const newEntries = unpackedChunks
          .filter(notEmpty)
          .map((chunk) => [chunk.id, chunk]) as [string, DocumentChunkTextContent | undefined][];
        return Object.fromEntries(
          filteredExistingEntries.concat(newEntries),
        );
      });
    })();
    // we use chunkContentMapRef here instead of chunkContentMap itself to prevent an infinite render loop.
  }, [chunkContentMapRef, chunksWhichShouldBeLoaded, inlineFileChunks, setChunkContentMap]);
  return chunkContentMap;
}

function useStartChunkIndex(
  doc: PartialDocument<FirstClassDocument, 'currentScrollPosition'> | null,
  chunkArray: DocumentChunk[] | null,
): number | undefined {
  const [startChunkIndex, setStartChunkIndex] = useState<number | undefined>();

  useEffect(() => {
    if (startChunkIndex !== undefined) {
      return;
    }
    if (!doc) {
      return;
    }
    const serializedPosition = doc.currentScrollPosition?.serializedPosition;
    if (!serializedPosition) {
      // doc is loaded but no current scroll position, so this is the first open of this doc.
      setStartChunkIndex(0);
      return;
    }
    let topLevelNodeIndex = extractTopLevelNodeIndex(serializedPosition);
    if (topLevelNodeIndex === undefined) {
      return;
    }
    if (!chunkArray) {
      return;
    }
    let chunkIndex = 0;
    for (const chunk of chunkArray) {
      if (chunk.html_child_node_count === null) {
        exceptionHandler.captureException('chunk is missing top level node count', {
          extra: {
            chunk,
            chunkIndex,
            topLevelNodeIndex,
            serializedPosition,
          },
        });
        break;
      }
      if (topLevelNodeIndex < chunk.html_child_node_count) {
        break;
      }
      topLevelNodeIndex -= chunk.html_child_node_count;
      chunkIndex += 1;
    }
    logger.debug('calculated start chunk index', {
      serializedPosition,
      topLevelNodeIndex,
      chunkIndex,
    });
    setStartChunkIndex(chunkIndex);
  }, [chunkArray, doc, startChunkIndex]);

  return startChunkIndex;
}

/**
 * Loads and decrypts 'window' of chunks around the last reading position of the given doc.
 * Chunks are guaranteed to be in order and have images inlined as base64 data URLs.
 *
 * For compatibility, if document is not chunked, returns a one-chunk array with full document content.
 *
 * @param docId
 *
 * @return Object array of chunk contents, plus a function to load or unload a chunk with a given ID.
 */
export function useChunkedDocumentContent(docId: string | undefined) {
  const [doc, { isFetching }] = usePartialDocument(
    docId,
    ['id', 'currentScrollPosition', 'category', 'html', 'content', 'url', 'parsed_doc_id', 'transientData'],
    {
      shouldPollStateIfMissing: true,
    },
  );

  // TODO: this code is duplicated from useDocumentContentFromState().
  //   eventually that hook code should be removed and any uses of it should use useChunkedDocumentContent() instead.
  const unchunkedContent = useMemo(() => {
    if (!doc) {
      return null;
    }
    return doc.category === Category.Highlight ? doc.html : doc.transientData.content;
  }, [doc]);

  const hasDoc = Boolean(doc);
  const parsedDocId = doc && isFirstClassDocument(doc) && doc?.parsed_doc_id;
  const articleDomain = doc && isArticle(doc) && getUrlDomain(doc.url);

  useEffect(() => {
    if (!docId || !hasDoc) {
      return;
    }

    /*
      This is async. It isn't guaranteed the content will be there by the time this function returns.
      This is here to ensure the content is requested whenever we're trying to use it. It's called
      elsewhere too (to pre/load the content) but let's be safe. Even if the other calls are
      accidentally broken / removed, this will save us.
    */
    fetchDocumentContent([docId]);
    if (articleDomain) {
      fetchRelatedRSS([articleDomain]);
    }
  }, [docId, hasDoc, parsedDocId, articleDomain]);

  const spine = useMemo(
    () =>
      doc?.transientData.spine &&
      Object.entries(doc.transientData.spine)
        .map(([index, id]) => [Number.parseInt(index, 10), id] as [number, string])
        .sort(([idx1, _id1], [idx2, _id2]) => idx1 - idx2)
        .map(([_, id]) => id),
    [doc?.transientData.spine],
  );
  const chunkMap = doc?.transientData.chunks;
  const chunkArray = useMemo(() => {
    if (!spine || !chunkMap) {
      return null;
    }
    const chunks = spine.map((id) => chunkMap[id]);
    if (!chunks.every(notEmpty)) {
      return null;
    }
    return chunks;
  }, [chunkMap, spine]);

  const startChunkIndex = useStartChunkIndex(doc, chunkArray);
  const chunkIndexesToLoad = useMemo(() => {
    if (startChunkIndex === undefined) {
      return undefined;
    }
    return Array.from({ length: 3 }, (_, index) => startChunkIndex - 1 + index).filter(
      (index) => index >= 0,
    );
  }, [startChunkIndex]);

  const [loadedDocumentChunkIds, setloadedDocumentChunkIds] = useState<{
    [id: string]: boolean;
  }>({});

  useEffect(() => {
    logger.debug('clearing loaded chunks');
    setloadedDocumentChunkIds({});
  }, [docId]);

  const styleChunks = useMemo(() => {
    if (!chunkMap) {
      return [];
    }
    return Object.values(chunkMap)
      .filter((chunk) => chunk.type === DocumentChunkType.Style);
  }, [chunkMap]);

  const allChunkIdsToLoad = useMemo(() => {
    const styleChunkIds = styleChunks.map((chunk) => chunk.internal_id);
    return [...Object.keys(loadedDocumentChunkIds), ...styleChunkIds];
  }, [loadedDocumentChunkIds, styleChunks]);

  const chunkContentMap = useDocumentChunkContentMap(doc, allChunkIdsToLoad);

  const setChunkContentState = useCallback((chunkId: string, state: 'loaded' | 'unloaded') => {
    setloadedDocumentChunkIds((ids) => {
      switch (state) {
        case 'loaded':
          if (chunkId in ids) {
            return ids;
          }
          logger.debug(`chunk content from store LOAD`, { id: chunkId });
          return {
            ...ids,
            [chunkId]: true,
          };
        case 'unloaded': {
          if (!(chunkId in ids)) {
            return ids;
          }
          logger.debug(`chunk content from store UNLOAD`, { id: chunkId });
          const newIds = { ...ids };
          delete newIds[chunkId];
          return newIds;
        }
      }
    });
  }, []);

  useEffect(() => {
    if (chunkIndexesToLoad === undefined) {
      return;
    }
    const idsToLoad = Object.fromEntries(
      chunkIndexesToLoad
        .map((index) => spine?.[index])
        .filter(notEmpty)
        .map((id) => [id, true]),
    );
    setloadedDocumentChunkIds((ids) => ({
      ...ids,
      ...idsToLoad,
    }));
  }, [chunkIndexesToLoad, spine]);

  const webviewDocumentChunks: WebviewDocumentChunk[] | undefined = useMemo(
    () =>
      chunkArray
        ?.map((chunk, index) => {
          const id = chunk?.internal_id;
          if (chunk.html_child_node_count === null) {
            exceptionHandler.captureException(
              'generating WebviewDocumentChunk: text chunk has no html_child_node_count',
              {
                extra: {
                  id,
                  index,
                  chunkType: chunk.type,
                  dataLength: chunk.data.length,
                },
              },
            );
            return undefined;
          }
          return {
            id,
            index,
            filename: chunk.filename,
            content: chunkContentMap?.[id]?.text ?? null,
            html_child_node_count: chunk.html_child_node_count,
          };
        })
        ?.filter(notEmpty),
    [chunkContentMap, chunkArray],
  );

  const normalizedWebviewDocumentChunks: WebviewDocumentChunk[] = useMemo(() => {
    if (webviewDocumentChunks) {
      return webviewDocumentChunks;
    }
    if (unchunkedContent) {
      return [
        {
          content: unchunkedContent,
          index: 0,
          filename: 'single-chunk-full-content',
          // only used for chunked docs, so doesn't need to be accurate.
          html_child_node_count: 0,
          // NOTE: this ID is used to calculate html_child_node_count inside the content frame, do not change.
          id: 'single-chunk-full-content',
        },
      ];
    }
    return [];
  }, [webviewDocumentChunks, unchunkedContent]);

  const webviewStyleChunks = useMemo(() => styleChunks
    .map((chunk) => {
      const content = chunkContentMap?.[chunk.internal_id]?.text;
      if (!content) {
        return null;
      }
      return {
        id: chunk.internal_id,
        filename: chunk.filename,
        content,
      };
    })
    .filter(notEmpty),
  [styleChunks, chunkContentMap]);

  const chunks: WebviewChunks = useMemo(() => ({ document: normalizedWebviewDocumentChunks, style: webviewStyleChunks }), [normalizedWebviewDocumentChunks, webviewStyleChunks]);

  useEffect(() => {
    const loaded = webviewDocumentChunks?.filter((c) => c.content);
    logger.debug(`chunks ${loaded?.length}/${webviewDocumentChunks?.length} loaded`, {
      loaded: loaded?.map((c) => [c.index, c.id]),
    });
  }, [webviewDocumentChunks]);

  return useMemo(
    () => ({
      setChunkContentState,
      chunks,
      isFetching,
    }),
    [chunks, isFetching, setChunkContentState],
  );
}
