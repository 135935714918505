import { DocumentChunkMap } from '../../types/chunkedDocuments';
import makeLogger from '../../utils/makeLogger';
import { unpackChunk } from './unpackChunk';

const logger = makeLogger(__filename, { shouldLog: false });

export function unpackChunkWithFilename(filename: string, chunkMap: DocumentChunkMap) {
  const absoluteFilename = makeUrlAbsolute(filename);
  const filenameToChunkId = getFilenameToChunkId(chunkMap);
  const chunkId = findFilenameReference(absoluteFilename, filenameToChunkId);

  if (!chunkId) {
    logger.warn('Could not find chunk with filename', { filename });
    return undefined;
  }

  const chunk = chunkMap?.[chunkId];
  if (!chunk) {
    logger.warn('Could not find chunk with ID', { chunkId });
    return undefined;
  }

  return unpackChunk(chunk);
}

function getFilenameToChunkId(chunkMap: DocumentChunkMap) {
  return Object.fromEntries(
    Object.values(chunkMap ?? {}).map((chunk) => [chunk.filename, chunk.internal_id]),
  );
}

/**
 * Converts a relative file URL to an absolute URL ase we don't support
 * relative file URLs in the chunked content map.
 */
function makeUrlAbsolute(url: string): string {
  const prefixReplacements = [
    [/^\.\.\/+/g, ''], // Remove all ../ from start
    [/^\.\//, ''], // Remove ./ from start
    [/^\//, ''], // Remove / from start
  ] as const;

  return prefixReplacements.reduce(
    (path, [pattern, replacement]) => path.replace(pattern, replacement),
    url,
  );
}

/**
 * Finds the chunk ID for a given filename.
 *
 * We check for all root folder variations of this filename and return the first match.
 */
function findFilenameReference(filename: string, filenameToChunkId: { [filename: string]: string; }) {
  const absoluteFilenames = [filename, ...appendEpubRootFolder(filename)];
  for (const absoluteFilename of absoluteFilenames) {
    const chunkId = filenameToChunkId[absoluteFilename];
    if (chunkId) {
      return chunkId;
    }
  }
  return undefined;
}

/**
 * The EPUB convention is to call the root content folder either OEBPS or OPF.
 */
const EPUB_ROOT_FOLDERS = ['OEBPS', 'OPF'];


function appendEpubRootFolder(filename: string): string[] {
  return EPUB_ROOT_FOLDERS.map((rootFolder) => filename.startsWith(rootFolder) ? filename : `${rootFolder}/${filename}`);
}
